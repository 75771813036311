import React, { useEffect, useRef, useState } from 'react';

export const LazyBackgroundImage = ({ className = '', bgImage, children }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const bgRef = useRef();

  useEffect(() => {
    if (!bgRef.current) return;
    
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {          
          setIsLoaded(true);
        }
      },
      { threshold: 0.1 },
    );

    observer.observe(bgRef.current);

    return () => observer.disconnect();
  }, []);

  return (
    <div
      ref={bgRef}
      className={`${className} ${
        isLoaded ? 'bg-cover bg-center' : 'bg-gray-200'
      }`}
      style={{
        backgroundImage: isLoaded ? `url(${bgImage})` : 'none',
      }}
    >
      {children}
    </div>
  );
};
