import { RequestFeedback } from '@features/request-feedback';
import { bem } from '@lib/bem';
import StaticIcon from '@ui/StaticIcon';
import { Mockup } from '@ui/Mockup';

import Typography from '@ui/Typography';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import Image from '@ui/Image';
import { RegisterPageButton } from '@features/registration';
import CheckText from '../CheckText';
import StarIcon from '../../../../../static/images/four-pointed-star.svg';

import './HeroSection.scss';

const {
  block,
  element,
} = bem('hero-section');

const Benefits = ({
  benefits,
  t,
}) => {
  return (
    <div className={clsx(
      'xl:grid-cols gap-4 py-8 sm:space-y-6 sm:py-7 md-lg:flex-col md-lg:space-y-7 xl:flex',
    )}
    >
      {
        benefits.map((benefit, i) => (
          <div key={i} className="xl:col-4 sm:flex sm:flex-col sm:items-center md-lg:text-left">
            <StaticIcon folder="main-page" name={benefit.icon} />
            <Typography variant="header5" className="mt-2 lg-only:h-[60px]">
              {benefit.title}
            </Typography>
            <Typography color="secondary300" className="mt-2 text-lg">
              {benefit.body}
            </Typography>
          </div>
        ))
      }
    </div>
  );
};

const Regalia = ({ item: { name, path } }) => (
  <a 
    href={path} 
    target="_blank" 
    className={clsx(
      'mb-3 flex w-fit items-center gap-[6px] rounded-full border border-tertiary300 bg-white p-[4px_16px_6px_12px]',
      'hover:border-primary400 hover:outline hover:outline-[3px] hover:outline-tertiary300',
      'sm:border-primary400 sm:outline sm:outline-[3px] sm:outline-tertiary300 xl-down:mx-auto',
      'transition-colors duration-500 ease-in-out',
    )}
  >
    <StarIcon className="fill-[#0B0B0D]" />
    <Typography className="text-sm">{name}</Typography>
  </a>
);

const HeroSection = ({
  className,
  t,
  containerClass,
}) => {
  const checkTexts = t('hero_section.check_texts');

  return (
    <section data-anchor="" {...block({}, clsx(className, '!border-t-0 !border-b-tertiary400 bg-border-gradient !pt-0'))}>
      <div className="flex w-full justify-center rounded-b-[31px] bg-tertiary200 pt-12">
        <div className={clsx('grid-layout sm:mt-8 md-lg:mt-12', containerClass)}>
          <div className="xl:flex">
            <div className="xl:col-7 flex flex-col xl:mt-[174px]">
              <Regalia item={t('hero_section.regalia')} />
              <Typography variant="header1">
                {t('hero_section.header')}
              </Typography>
              <Typography
                className="mt-3"
                variant="subhead1"
                color="secondary300"
              >
                {t('hero_section.subheader')}
              </Typography>
              <div {...element('buttons-group', {}, '!my-4.5')}>
                <RegisterPageButton>{t('try_free')}</RegisterPageButton>
                <RequestFeedback.Demo.Button className="sm:mt-2 md-up:ml-2" outline>
                  {t('request_demo')}
                </RequestFeedback.Demo.Button>
              </div>
              <div className="flex justify-between xl-down:flex-col xl-down:items-center xl-down:gap-7">
                <div className="flex flex-col gap-2 sm:gap-3 xl-down:items-center">
                  {
                    React.Children.toArray(checkTexts.map((text) => (
                      <CheckText text={text} />
                    )))
                  }
                </div>
                <div className="relative mt-4.5 w-[192px] xl:self-end">
                  <div className="mx-auto w-fit rounded-full bg-white p-4.5">
                    <Image src="/images/main-page/qr-new.webp" width={80} height={80} />
                  </div>
                  <Image src="/images/main-page/rotating-text.webp" className="rotating-text absolute top-[-20px] right-0" alt="с помощью qr-кода" />
                </div>
              </div>
            </div>
            <Mockup filled className={clsx('backdrop-blur-[10px] xl:ml-auto xl:mt-8 xl-down:mt-[-44px] xl-down:flex xl-down:justify-center')}>
              <Mockup.Screen>
                <video
                  autoPlay
                  loop
                  muted
                  playsInline
                  poster="/ws-hero-first-frame.jpg"
                  width="247"
                  height="536"
                >
                  <source src="/ws-hero.mp4#t=0.1" type="video/mp4" />
                  Your browser does not support HTML5 video.
                </video>
              </Mockup.Screen>
            </Mockup>
          </div>
          <Benefits benefits={t('benefits')} t={t} />
        </div>
      </div>
    </section>
  );
};

HeroSection.defaultProps = {
  className: '',
};

HeroSection.propTypes = {
  t: PropTypes.func.isRequired,
  className: PropTypes.string,
  containerClass: PropTypes.string,
};

export default HeroSection;
