import { t } from '@lib/i18n';
import Typography from '@ui/Typography';
import clsx from 'clsx';
import { RegisterPageButton } from '@features/registration';
import StaticIcon from '@ui/StaticIcon';
import Slider from '@ui/Slider';
import { LazyBackgroundImage } from '@ui/LazyBackgroundImage';
import { BlockCaption } from '../BlockCaption';

const tr = (key) => t(`main_page.application.${key}`);
const items = tr('items');

const bgImage = {
  0: '/images/main-page/application-bg-1.avif',
  1: '/images/main-page/application-bg-2.avif',
  2: '/images/main-page/application-bg-3.avif',
  3: '/images/main-page/application-bg-4.avif',
};

const Dots = ({ count, className, currentIndex }) => {
  return (
    <div className={clsx(className, 'flex gap-2 rounded-full border border-[#FFFFFFA3] bg-[#FFFFFF52] py-1 px-1.5 backdrop-blur-lg')}>
      {Array(count).fill(0).map((x, index) => {
        if (index === currentIndex) {
          return (
            <div key={index} className="relative">
              <StaticIcon name="slider-dot-circle" />
              <StaticIcon name="slider-dot-semi-circle" className="absolute top-0.5 left-[-3px]" />
            </div>
          );
        }
        return (
          <StaticIcon key={index} name="inactive-slider-dot" />
        );
      })}
    </div>
  );
};

export const Application = () => {
  return (
    <section className="bg-[linear-gradient(180deg,#FFFFFF_0%,rgba(255,255,255,0.817367)_23.29%,rgba(255,255,255,0.563662)_58.48%,rgba(255,255,255,0)_100%)]">
      <div className="grid-layout mx-auto py-8 lg-down:mb-[137px]">
        <BlockCaption>
          {tr('caption')}
        </BlockCaption>
        <Typography variant="header2" className="mt-4 text-center">{tr('title')}</Typography>

        <div className="relative mt-7">
          <Slider
            settings={{
              slidesToShow: 4,
              arrows: false,
              responsive: [
                {
                  breakpoint: 1365,
                  settings: {
                    slidesToShow: 3.1,
                  },
                },
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 2.1,
                  },
                },
                {
                  breakpoint: 768,
                  settings: {
                    slidesToShow: 1.1,
                    arrows: false,
                  },
                },
              ],
              dots: true,
            }}
            gap={2}
          >
            {items.map(({ title, text }, index) => {
              return (
                <LazyBackgroundImage 
                  key={title}
                  className="relative h-[400px] w-[316px] rounded-[32px] border border-tertiary300 bg-cover bg-no-repeat p-4 text-start sm:h-[300px]"
                  bgImage={bgImage[index]}
                >
                  <Typography variant="header4" className={clsx()}>{title}</Typography>
                  <Typography variant="subhead1" className={clsx('mt-2')}>{text}</Typography>
                  <Dots count={items.length} currentIndex={index} className="absolute bottom-4 right-4 xl-down:hidden" />
                </LazyBackgroundImage>
              );
            })}
          </Slider>
        </div>

        <div className="mx-auto mt-7 w-fit">
          <RegisterPageButton blank={false}>
            {tr('button')}
          </RegisterPageButton>
        </div>
      </div>
    </section>
  );
};
